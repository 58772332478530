//s#Url for ------------ app,tvs,qa,daimler,hmie
// export const s3Url = "https://s3.ap-south-1.amazonaws.com/prod.xcpep.com/";

// export const s3Url = "https://tvs-xcpep.s3.ap-south-1.amazonaws.com/";

// export const s3Url = "https://prod-daimler.s3.ap-south-1.amazonaws.com/";
// export const s3Url = "https://hmie-xcpep.s3.ap-south-1.amazonaws.com/"
const currentUrl = window.location.href;
const getS3url = () => {
  let S3url = "";
  // if(!isUndefined(currentUrl)){

  //     if(currentUrl.includes("qa")){
  //       S3url= "https://qa-xcpep.s3.ap-south-1.amazonaws.com/";
  //     }else if(currentUrl.includes("app")){
  //       S3url = "https://s3.ap-south-1.amazonaws.com/prod.xcpep.com/";
  //     }
  // }
  if (typeof currentUrl !== "undefined") {
    if (currentUrl.includes("tvs-companion.xcpep.com")) {
      S3url = "https://tvs-xcpep.s3-accelerate.amazonaws.com/";
    } else if (currentUrl.includes("daimler-companion.xcpep.com")) {
      S3url = "https://prod-daimler.s3.ap-south-1.amazonaws.com/";
    } else if (currentUrl.includes("app-companion.xcpep.com")) {
      S3url = "https://s3.ap-south-1.amazonaws.com/prod.xcpep.com/";
    } else if (currentUrl.includes("hmie-companion.xcpep.com")) {
      S3url = "https://hmie-xcpep.s3-accelerate.amazonaws.com/";
    } else if (currentUrl.includes("mnm-companion.xcpep.com")) {
      S3url = "https://mnm-xcpep.s3-accelerate.amazonaws.com/";
    } else if (currentUrl.includes("bal-companion.xcpep.com")) {
      S3url = "https://bal-xcpep.s3.ap-south-1.amazonaws.com/";
    } else if (currentUrl.includes("ae-companion.xcpep.com")) {
      S3url = "https://ae-xcpep.s3-accelerate.amazonaws.com/";
    } else if (currentUrl.includes("olaelectric-companion.xcpep.com")) {
      S3url = "https://ola-xcpep.s3.ap-south-1.amazonaws.com/";
    } else if (currentUrl.includes("mlmm-companion.xcpep.com")) {
      S3url = "https://meml-xcpep.s3-accelerate.amazonaws.com/";
    } else if (currentUrl.includes("subscription-companion.xcpep.com")) {
      S3url = "https://subscription-xcpep.s3-accelerate.amazonaws.com/";
    } else if (currentUrl.includes("av-companion.xcpep.com")) {
      S3url = "https://av-xcpep.s3-accelerate.amazonaws.com/";
    } else if (currentUrl.includes("cnhi-companion.xcpep.com")) {
      S3url = "https://cnhi-xcpep.s3-accelerate.amazonaws.com/";
    } else if (currentUrl.includes("tm-companion.xcpep.com")) {
      S3url = "https://tm-xcpep.s3-accelerate.amazonaws.com/";
    } else if (currentUrl.includes("ucal-companion.xcpep.com")) {
      S3url = "https://ucal-xcpep.s3-accelerate.amazonaws.com/";
    } else if (currentUrl.includes("mgi-companion.xcpep.com")) {
      S3url = "https://mgi-xcpep.s3-accelerate.amazonaws.com/";
    } else if (currentUrl.includes("gcl-companion.xcpep.com")) {
      S3url = "https://gcl-xcpep.s3-accelerate.amazonaws.com/";
    } else if (currentUrl.includes("hrid-companion.xcpep.com")) {
      S3url = "https://hrid-xcpep.s3-accelerate.amazonaws.com/";
    } else if (currentUrl.includes("bsl-companion.xcpep.com")) {
      S3url = "https://bsl-xcpep.s3-accelerate.amazonaws.com/";
    } else if (currentUrl.includes("ax-companion.xcpep.com")) {
      S3url = "https://ax-xcpep.s3-accelerate.amazonaws.com/";
    } else if (currentUrl.includes("em-companion.xcpep.com")) {
      S3url = "https://em-xcpep.s3-accelerate.amazonaws.com/";
    } else if (currentUrl.includes("vl-companion.xcpep.com")) {
      S3url = "https://vl-xcpep.s3-accelerate.amazonaws.com/";
    } else if (currentUrl.includes("ag-companion.xcpep.com")) {
      S3url = "https://ag-xcpep.s3-accelerate.amazonaws.com/";
    } else if (currentUrl.includes("aa-companion.xcpep.com")) {
      S3url = "https://aa-xcpep.s3-accelerate.amazonaws.com/";
    }
  }
  return S3url;
};
export const s3Url = getS3url();